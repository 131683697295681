import React, { lazy } from "react"
import { Redirect } from "react-router-dom"

// Profile
const UserProfile = lazy(() => import("../pages/Authentication/user-profile"))
// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Register = lazy(() => import("../pages/Authentication/Register"));
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"));

// Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard/index"));
// MYLINKS 
const Players = lazy(() => import("../pages/players"));
const PlayerTypes = lazy(() => import("../pages/player-types"));
const EnterRound = lazy(() => import("../pages/EnterRound"));
const Tournament = lazy(() => import("../pages/Tournament"));
const Tour = lazy(() => import("../pages/Tour"));
const SubAdmin = lazy(() => import("../pages/Subadmin"));
const ScoreDetail = lazy(() => import("../pages/Scoredetails"));
const Generaldashboard = lazy(() => import("../pages/General"));
const Teedashboard = lazy(() => import("../pages/Tee"));
const Approachdashboard = lazy(() => import("../pages/Approach"));
const ShortGameDashboard = lazy(() => import("../pages/Shortgame"));
const Puttingdashboard = lazy(() => import("../pages/putting"));
// graph 
const TeeGraph = lazy(()=> import ("../pages/Tee/TeeGraph"))
const Score = lazy(()=> import ("../pages/Tee/Tour/index"))
const ShortGameVisual = lazy(()=> import ("../pages/Shortgame/shortGameVisual"))
const PuttingVisual = lazy(()=> import ("../pages/putting/puttingVisual"))
const ApproachVisual = lazy(()=> import ("../pages/Approach/ApproachVisuals"))












const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/players", component: Players },
  { path: "/player_types", component: PlayerTypes },
  { path: "/EnterRound", component: EnterRound },
  { path: "/Tour", component: Tour },
  { path: "/SubAdmin", component: SubAdmin },
  { path: "/Tournaments", component: Tournament },
  { path: "/ScoreDetail", component: ScoreDetail },
  { path: "/Generaldashboard", component: Generaldashboard },
  { path: "/Teedashboard", component: Teedashboard },
  { path: "/Approachdashboard", component: Approachdashboard },
  { path: "/ShortGameDashboard", component: ShortGameDashboard },
  { path: "/Puttingdashboard", component: Puttingdashboard },
  // Graph 
  { path: "/Teevisuals", component: TeeGraph },
  { path: "/Shortgamevisuals", component: ShortGameVisual },
  { path: "/puttingVisual", component: PuttingVisual },
  { path: "/approachvisual", component: ApproachVisual },


  { path: "/Score", component: Score },















  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  
]

export { authProtectedRoutes, publicRoutes }
